import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/render/project/src/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "setting-up-a-new-domain"
    }}>{`Setting up a new domain`}</h1>
    <h2 {...{
      "id": "dns-and-certs"
    }}>{`DNS and certs`}</h2>
    <p>{`Register your domain in AWS Route53. In Route53 create new A and AAAA records
for the new domain to map the v4 and v6 ips of the proxy server on Fly`}</p>
    <p>{`Get the ips for our proxy servers on Fly`}</p>
    <pre><code parentName="pre" {...{}}>{`fly ips list --app platform-us-proxy
fly ips list --app platform-ca-proxy
`}</code></pre>
    <ol>
      <li parentName="ol">{`Create new certs on the appropriate proxy service.`}</li>
    </ol>
    <p>{`Example`}</p>
    <pre><code parentName="pre" {...{}}>{`fly certs create "*.kualihub.com" --app platform-us-proxy
fly certs create "*.kualihubca.com" --app platform-ca-proxy
`}</code></pre>
    <p>{`Follow the instructions to add verify ownership of the domain`}</p>
    <p>{`Verify the cert was created:`}</p>
    <pre><code parentName="pre" {...{}}>{`fly certs list --app platform-us-proxy
fly certs list --app platform-ca-proxy
`}</code></pre>
    <h2 {...{
      "id": "csp"
    }}>{`CSP`}</h2>
    <p><a parentName="p" {...{
        "href": "/scripts/generate-csp"
      }}>{`Update the CSP`}</a>{` with your new domain`}</p>
    <h2 {...{
      "id": "tenant-manager"
    }}>{`Tenant Manager`}</h2>
    <p>{`Navigate to `}<inlineCode parentName="p">{`config/prod.exs`}</inlineCode>{` in the tenant-manager project and add your new
domain to the :domains config.`}</p>
    <h2 {...{
      "id": "sentry"
    }}>{`Sentry`}</h2>
    <p>{`Log in to sentry and add your domain to the Allowed Domains for each project in
kuali-build`}</p>
    <h2 {...{
      "id": "saml"
    }}>{`SAML`}</h2>
    <p>{`Add your domain to the 2 saml-meta.xml files in identity Log in to incommon and
update the saml meta data there`}</p>
    <h2 {...{
      "id": "emails"
    }}>{`Emails`}</h2>
    <p>{`Go to mailgun.com configuration and add a new domain. Use mail. `}{`<`}{`new
domain`}{`>`}</p>
    <p>{`Follow the mailgun instructions to configure for email.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      